




























































































































import { Vue, Component } from 'vue-property-decorator'
import commonModel from '@/models/common'
import { iProfile, iBoxes, iAlertsHistories, iBoxDataList, iAlertsHistoriesCounts } from '@/@types/common';
import dayjs from 'dayjs'
import Menu from '@/components/Menu.vue'

@Component({
  components: {
    Menu
  }
})
export default class Alarm extends Vue {

  private dayjs = dayjs;
  private isShowCalendar = false;
  private currentUserId = '';
  private currentYear = dayjs().format('YYYY');
  private groupMembers:iProfile[] = [];
  private boxes:iBoxes[] = [];
  private isDayApi:boolean[] = []
  private rawTableData:iAlertsHistories[] = []
  private rawCalendarData:iAlertsHistoriesCounts[] = []
  private selectedTableData:iAlertsHistories[] = []
  private tableHeader:{text?:string, value?:string, align?:string}[] = [
    {text: '発生日時', value: 'datetime', align: 'center'},
    {text: '牛舎名', value: 'box_name', align: 'center'},
    {text: '項目名', value: 'data_type_name', align: 'center'},
    {text: '状態', value: 'status', align: 'center'},
  ]
  private currentSelectBox = 0
  private selectBoxList:{id?:number, name?:string}[] = [{id: 0, name: '全て'}]
  private currentSelectItem = 'all'
  private selectItemList:{key?:string, name?:string}[] = [{key: 'all', name: '全て'}]
  private calendarDetail: {isShow:boolean, date:string, list: iAlertsHistories[]} = {isShow: false, date: '', list: []}

  private async created (): Promise<void> {

    this.isDayApi.push(true)

    // 現在のアカウントで取得できるBOX一覧を取得する
    const profile = await commonModel.getMyProfile();
    if (profile.admin_flg) {
      const membres = await commonModel.getGroupMembres();
      this.groupMembers = membres
    } else {
      this.groupMembers = [profile]
    }

    this.currentUserId = this.groupMembers[0].user_id
    // ローカルストレージにユーザ情報が存在する場合はそのユーザを使用する
    const localUserId:string = localStorage.getItem('currentUserId') || ''
    if (localUserId && this.groupMembers.some(li => li.user_id == localUserId)) {
      this.currentUserId = localUserId
    }
    localStorage.setItem('currentUserId', this.currentUserId)

    this.loadUserData()
    this.isDayApi.pop()
  }

  private async loadUserData (): Promise<void> {
    this.isDayApi.push(true)
    // BOX情報とアラート情報の取得
    this.boxes = await commonModel.getBoxes(this.currentUserId);
    this.rawTableData = await commonModel.getAlertsHistories(50, this.currentUserId)

    // BOX情報から、選択できるBOXリストを作成
    for (let idx = 0; idx < this.boxes.length; idx++) {
      this.selectBoxList.push({id: this.boxes[idx].id, name: this.boxes[idx].name})
    }

    // BOX情報から、選択できるItemリストを作成
    for (let bIdx = 0; bIdx < this.boxes.length; bIdx++) {
      const box:iBoxes = this.boxes[bIdx]
      if (box && box.box_type && box.box_type.data_list.length > 0) {
        for (let sIdx = 0; sIdx < box.box_type.data_list.length; sIdx++) {
          this.selectItemList.push({key: box.box_type.data_list[sIdx].key, name: box.box_type.data_list[sIdx].name})
        }
      }
      if (box && box.wireless_type && box.wireless_type.data_list.length > 0) {
        for (let sIdx = 0; sIdx < box.wireless_type.data_list.length; sIdx++) {
          this.selectItemList.push({key: box.wireless_type.data_list[sIdx].key, name: box.wireless_type.data_list[sIdx].name})
        }
      }
    }

    this.rawTableData = await this.sortTableData(this.rawTableData)
    this.selectTableData()

    this.loadCalendarData()
    this.isDayApi.pop()
  }

  private async loadCalendarData (addYear=0): Promise<void> {
    this.isDayApi.push(true)
    this.currentYear = String(parseInt(this.currentYear) + addYear)
    this.rawCalendarData = []
    this.rawCalendarData = await commonModel.getAlertsHistoriesCounts(this.currentUserId, this.currentYear + '-01-01', this.currentYear + '-12-31')
    this.isDayApi.pop()
  }

  private async sortTableData (tableData: iAlertsHistories[]): Promise<iAlertsHistories[]> {
    this.isDayApi.push(true)
    // アラート情報のkeyからBOX情報のnameを探して、リスト表示用の配列を作成
    for (let idx = 0; idx < tableData.length; idx++) {
      const box:iBoxes = this.boxes.find(li => li.id == tableData[idx].box_id) || {id:0}
      if (box && box.name) {
        tableData[idx]['box_name'] = box.name
      }
      if (box && box.box_type && box.box_type.data_list.length > 0) {
        const sensor:iBoxDataList = box.box_type.data_list.find(li => li.key == tableData[idx].data_type_key) || {}
        if (sensor && sensor.name) {
          tableData[idx]['data_type_name'] = sensor.name
        }
      }
      if (box && box.wireless_type && box.wireless_type.data_list.length > 0) {
        const sensor:iBoxDataList = box.wireless_type.data_list.find(li => li.key == tableData[idx].data_type_key) || {}
        if (sensor && sensor.name) {
          tableData[idx]['data_type_name'] = sensor.name
        }
      }
    }
    this.isDayApi.pop()
    return tableData
  }

  private async selectTableData (): Promise<void> {
    this.isDayApi.push(true)
    // 元のテーブルデータからコピーする
    this.selectedTableData = this.rawTableData.filter((li) => {
      if (this.currentSelectBox !== 0 && li.box_id !== this.currentSelectBox) {
        return
      }
      if (this.currentSelectItem !== 'all' && li.data_type_key !== this.currentSelectItem) {
        return
      }
      return li
    });
    this.isDayApi.pop()
  }

  private searchCalendarData (date:string): number {
    const data = this.rawCalendarData.find(li => li.date == dayjs(date, 'YYYY-M-D').format('YYYY-MM-DD')) || {}
    if (data.count) {
      return data.count
    } else {
      return -1
    }
  }

  private async loadCalendarDetail (): Promise<void> {
    this.isDayApi.push(true)
    this.calendarDetail.list = []
    const data = await commonModel.getAlertsHistories(100, this.currentUserId, this.calendarDetail.date, this.calendarDetail.date)
    this.calendarDetail.list = await this.sortTableData(data)
    this.calendarDetail.list = this.calendarDetail.list.reverse()
    this.isDayApi.pop()
  }



}
