









































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import commonModel from '@/models/common'
import { iProfile } from '@/@types/common';

@Component({
  components: {
  }
})
export default class Menu extends Vue {
  private version = '';
  private tradeName = '';
  private showNav = false;
  private title = '';
  @Prop({required: true}) private isDayApi!: boolean[];

  private async mounted (): Promise<void> {
    let set = commonModel.getSetting();
    this.version = set.version;

    const profile:iProfile = await commonModel.getMyProfile()
    if (profile.trade_name) {
      this.tradeName = profile.trade_name
    }

    if (this.$route.meta.title) {
      this.title = this.$route.meta.title
    }
  }

}
