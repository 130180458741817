





import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class Home extends Vue {

  private async created (): Promise<void> {
    // ルートページ
    // ここで、PC版とスマホ版のアクセスを振り分ける
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      // スマホ版
      this.$router.push('/m_latest')
    } else {
      // PC版
      this.$router.push('/monitor')
      // window.location.href = '/old/pc/data.php';
    }
  }

}
