import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Monitor from '../views/Monitor.vue'
import MMonitor from '../views/MMonitor.vue'
import MLatest from '../views/MLatest.vue'
import Setting from '../views/Setting.vue'
import Password from '../views/Password.vue'
import Production from '../views/Production.vue'
import Alarm from '../views/Alarm.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {responsive: true, isPublic: true}
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {responsive: true}
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    meta: {responsive: true, title: '設定'}
  },
  {
    path: '/production',
    name: 'Production',
    component: Production,
    meta: {responsive: true, title: '生産量'}
  },
  {
    path: '/alarm',
    name: 'Alarm',
    component: Alarm,
    meta: {responsive: true, title: '警報履歴'}
  },
  {
    path: '/password',
    name: 'Password',
    component: Password,
    meta: {responsive: true, title: 'パスワード変更'}
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Monitor,
    meta: {responsive: false, title: 'データ'}
  },
  {
    path: '/m_latest',
    name: 'MLatest',
    component: MLatest,
    meta: {responsive: true}
  },
  {
    path: '/m_monitor',
    name: 'MMonitor',
    component: MMonitor,
    meta: {responsive: true}
  },
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
