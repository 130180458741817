

















































































import { Vue, Component } from 'vue-property-decorator'
import commonModel from '@/models/common'
import { iBoxes, iLatest, iBoxDataList, iBattPercent, iProfile, iChartItems, iAlertsSettings } from '@/@types/common';
import dayjs from 'dayjs'
import Menu from '@/components/Menu.vue'
import BatteryIcon from '@/components/BatteryIcon.vue'

@Component({
  components: {
    Menu,
    BatteryIcon,
  }
})
export default class MLatest extends Vue {

  private showNav = false;
  private updateTime = '';
  private currentPercent?:number = 0;
  private boxes:iBoxes[] = [];
  private currentBox:iBoxes = {id:0};
  private currentBoxId = 0;
  private currentUserId = '';
  private currentFarmComfort = '';
  private latestData:{
    id:number, name:string, unit:string, value:number|undefined, type:string, key:string, label:string
  }[] = [];
  private groupMembers:iProfile[] = [];


  private async created (): Promise<void> {

    // 現在のアカウントで取得できるBOX一覧を取得する
    const profile:iProfile = await commonModel.getMyProfile();
    // 管理者の場合
    if (profile.admin_flg) {
      this.groupMembers = await commonModel.getGroupMembres();
    } else {
      this.groupMembers = [profile]
    }

    this.currentUserId = this.groupMembers[0].user_id
    // ローカルストレージにユーザ情報が存在する場合はそのユーザを使用する
    const localUserId:string = localStorage.getItem('currentUserId') || ''
    if (localUserId && this.groupMembers.some(li => li.user_id == localUserId)) {
      this.currentUserId = localUserId
    }
    localStorage.setItem('currentUserId', this.currentUserId)

    await this.loadUser()
    await this.loadBoxStatus()
  }

  private async loadUser (): Promise<void> {
    this.boxes = await commonModel.getBoxes(this.currentUserId);
    const localBoxId:number = parseInt(localStorage.getItem('currentBoxId') || '0')
    const isFindBox = this.boxes.some(li => li.id == localBoxId)

    // ローカルストレージに入っているBoxIdが現在のユーザ所有BOXに入っているか調べる
    if (isFindBox) {
      this.currentBoxId = localBoxId
    } else {
      this.currentBoxId = this.boxes[0].id;
      localStorage.setItem('currentBoxId', String(this.currentBoxId))
    }
  }

  private async loadBoxStatus (): Promise<void> {
    this.latestData = []
    this.updateTime = ''
    this.currentBox = this.boxes.find(box => box.id == this.currentBoxId) || {id:0}

    if (this.currentBox.box_type) {

      // BOX一覧をループして最新値取得APIのprimiseを作成
      const list:iBoxDataList[] = this.currentBox.box_type.data_list;
      let promiseArray = []
      for (let idx = 0; idx < list.length; idx++) {
        promiseArray.push(commonModel.getLatest(
          this.currentBoxId,
          list[idx].key || ''
        ))

        // もし体感温度が存在すれば、体感ストレスの取得も追加する
        if (list[idx].key == 'cor_temp') {
          promiseArray.push(commonModel.getLatest(this.currentBoxId, 'cor_thi'))
        }
      }
      const res:iLatest[] = await Promise.all(promiseArray)

      // APIから取得した内容で、最新値の配列を作成
      for (let idx = 0; idx < res.length; idx++) {
        const current:iBoxDataList = list.find(li => li.key == res[idx].data_type_key) || {}
        this.latestData.push({
          id: current.id || 0,
          name: current.name || '',
          unit: current.unit || '',
          type: current.type || '',
          key: res[idx].data_type_key || '',
          value: res[idx].value || undefined,
          label: res[idx].label || '',
        })
      }

      // 最終更新時刻とバッテリーを取得
      for (let idx = 0; idx < res.length; idx++) {
        this.updateTime = dayjs(res[idx].datetime).format('YYYY-MM-DD HH:mm')
      }
      const percent:iBattPercent = await commonModel.getBattPercent(this.currentBoxId)
      this.currentPercent = percent.percentage
      this.getFarmComfort()
    }
  }

  private async getFarmComfort (): Promise<void> {
    this.currentFarmComfort = ''
    // 選択されているBOXの畜舎環境を取得する
    const thi:iLatest = await commonModel.getLatest(this.currentBox.id, 'thi')
    const settings:iAlertsSettings[] = await commonModel.getAlertsSettings(this.currentBox.id)
    // THIの閾値設定を調べる
    const thresholdValues:number[] = []
    for (let idx = 0; idx < settings.length; idx++) {
      if (settings[idx].data_type_key && settings[idx].threshold_value && settings[idx].data_type_key == 'thi') {
        thresholdValues.push(settings[idx].threshold_value || 100)
      }
    }
    thresholdValues.sort()
    // 閾値に従って、快適度を分岐
    if (thi.value && thresholdValues[0] && thresholdValues[1]) {
      if (thresholdValues[1] <= thi.value) {
        this.currentFarmComfort = '危険'
      } else if (thi.value <= thresholdValues[1] && thresholdValues[0] <= thi.value) {
        this.currentFarmComfort = '注意'
      } else {
        this.currentFarmComfort = '快適'
      }
    }
  }

  private async changeBoxId (): Promise<void> {
    await this.loadBoxStatus()
    localStorage.setItem('currentBoxId', String(this.currentBoxId))
  }

  private async changeUserId (): Promise<void> {
    await this.loadUser()
    await this.loadBoxStatus()
    localStorage.setItem('currentUserId', this.currentUserId)
  }

  private clickSensorTitle (type:string, key:string, sensorType:string): void {
    if (type == 'all') {
      let chartItems:iChartItems[] = []
      if (this.currentBox.box_type) {
        const list:iBoxDataList[] = this.currentBox.box_type.data_list;
        for (let idx = 0; idx < list.length; idx++) {
          chartItems.push({
            boxId: this.currentBoxId,
            dataTypeKey: list[idx].key || '',
            type: list[idx].type || '',
            apiType: 'environment',
          })
        }
      }
      localStorage.setItem('chartItems', JSON.stringify(chartItems))
      localStorage.setItem('chartType', type)
      this.$router.push('/m_monitor')

    } else if (type == 'thiProd') {
      localStorage.setItem('currentBoxId', String(this.currentBoxId))
      localStorage.setItem('currentUserId', this.currentUserId)
      localStorage.setItem('chartItems', JSON.stringify([]))
      localStorage.setItem('chartType', type)
      this.$router.push('/m_monitor')

    } else if (type == 'detail') {
      let chartItems:iChartItems[] = [{
        boxId: this.currentBoxId,
        dataTypeKey: key,
        type: sensorType,
        apiType: 'environment',
      }]
      localStorage.setItem('chartItems', JSON.stringify(chartItems))
      localStorage.setItem('chartType', type)
      this.$router.push('/m_monitor')
    }
  }

}
