





















































































































































import { Vue, Component } from 'vue-property-decorator'
import commonModel from '@/models/common'
import { iProfile, iBoxes, iAlertsSettings, iAlertsRecipients } from '@/@types/common';
import Menu from '@/components/Menu.vue'

@Component({
  components: {
    Menu
  }
})
export default class Setting extends Vue {

  private profile:iProfile = {user_id: ''};
  private userStatusText = '';
  private boxStatusText = '';
  private mailStatusText = '';
  private userForm = false;
  private boxForm = false;
  private mailForm = false;
  private ruleRequired = (v:boolean) => !!v || '必須項目です';
  private ruleNumber = (v:string) => {if (v) return /^([-]?([0-9]*|0)(\.[0-9]+)?)?$/.test(String(v)) || '数値で入力してください'; else return true}
  private ruleMail = (v:string) => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v) || 'メールアドレスを入力してください';
  private currentBoxid = 0;
  private currentBox:iBoxes = {id:0}
  private boxes:iBoxes[] = [];
  private alertsList:{title:string, list:iAlertsSettings[]}[] = []
  private exAlertsList:iAlertsSettings[] = []
  private mailList:iAlertsRecipients[] = []
  private isAlertsConnect = false;

  private async created (): Promise<void> {
    this.profile = await commonModel.getMyProfile();
    this.boxes = await commonModel.getBoxes(this.profile.user_id);
    if (this.boxes[0] && this.boxes[0].id) {
      this.currentBoxid = this.boxes[0].id
      this.changeBoxid()
    }
  }

  private async changeBoxid (): Promise<void> {
    this.alertsList = []
    this.boxStatusText = ''
    this.isAlertsConnect = false
    // 参照渡し対策
    this.currentBox = JSON.parse(JSON.stringify(this.boxes.find(box => box.id == this.currentBoxid) || {id:0}))
    if (this.currentBox.box_type && this.currentBox.box_type.data_list) {
      const list = this.currentBox.box_type.data_list

      for (let idx = 0; idx < list.length; idx++) {
        // 表示する内容を設定
        if (list[idx].key == 'temp') {
          this.alertsList.push({title: '温度', list: [{data_type_key: 'temp', position: true,  threshold_value: undefined, label: '℃以上'}, {data_type_key: 'temp', position: false, threshold_value: undefined, label: '℃以下'}]})
        }
        if (list[idx].key == 'humi') {
          this.alertsList.push({title: '湿度', list: [{data_type_key: 'humi', position: true,  threshold_value: undefined, label: '％以上'}]})}
        if (list[idx].key == 'co2') {
          this.alertsList.push({title: 'CO2', list: [{data_type_key: 'co2', position: true,  threshold_value: undefined, label: 'ppm以上'}]})
        }
        if (list[idx].key == 'thi') {
          this.alertsList.push({title: 'THI', list: [{data_type_key: 'thi', position: true,  threshold_value: undefined, label: '以上'}, {data_type_key: 'thi', position: true, threshold_value: undefined, label: '以上'}]})
        }
        if (list[idx].key == 'ep') {
          this.alertsList.push({title: '牛舎積算電力', list: [{data_type_key: 'ep', position: true,  threshold_value: undefined, label: 'kWh以上'}]})
        }
      }
      this.exAlertsList = await commonModel.getAlertsSettings(this.currentBoxid)
      for (let pIdx = 0; pIdx < this.alertsList.length; pIdx++) {
        for (let cIdx = 0; cIdx < this.alertsList[pIdx].list.length; cIdx++) {
          const list = this.alertsList[pIdx].list[cIdx]
          const alertIdx = this.exAlertsList.findIndex(li =>
            li.box_id == this.currentBoxid &&
            li.data_type_key == list.data_type_key &&
            li.position == list.position
          )
          if (alertIdx !== -1) {
            list.threshold_value = this.exAlertsList[alertIdx].threshold_value
            list.id = this.exAlertsList[alertIdx].id
            this.exAlertsList.splice(alertIdx, 1)
          }
        }
      }
      this.isAlertsConnect = this.exAlertsList.some(li => li.box_id == this.currentBoxid && li.data_type_key == 'con' && li.position == false)

      // 配信先メールリストの作成
      this.mailList = await commonModel.getAlertsRecipients(this.currentBoxid)

    }
  }

  private async saveProfile (): Promise<void> {
    if ((this.$refs.userForm as Vue & { validate: () => boolean }).validate()) {
      this.userStatusText = ''
      await commonModel.putMyProfile(this.profile).then(response => {
        this.profile = response
        this.userStatusText = 'ユーザー情報を更新しました。'
      })
      .catch(() => {
        this.userStatusText = 'ユーザー情報の更新に失敗しました。'
      });
    }
  }

  private async saveBoxInfo (): Promise<void> {
    if ((this.$refs.boxForm as Vue & { validate: () => boolean }).validate()) {
      this.boxStatusText = ''
      // BOX名の保存
      if (this.currentBox.name) {
        await commonModel.putBoxName(this.currentBoxid, this.currentBox.name)
      }

      // 既存のアラートと設定させたアラートを比較して、編集した箇所のみAPIを叩く
      this.exAlertsList = await commonModel.getAlertsSettings(this.currentBoxid)
      for (let pIdx = 0; pIdx < this.alertsList.length; pIdx++) {
        for (let cIdx = 0; cIdx < this.alertsList[pIdx].list.length; cIdx++) {
          const list = this.alertsList[pIdx].list[cIdx]
          // キーにidが存在しなくてthreshold_valueに値が入っているものは、POSTで新規登録
          if (!list.id && list.threshold_value && list.data_type_key && 'position' in list) {
            await commonModel.postAlertsSettings(
              this.currentBoxid,
              list.data_type_key,
              Number(list.threshold_value),
              list.position || false
            )
          }
          else if (list.id) {
            const findAlert = this.exAlertsList.find(li => li.id == list.id)
            // 保存済みの設置値と新しい設定値が別の場合は、新しい値をPUTする
            if (findAlert && list.threshold_value && findAlert.threshold_value !== list.threshold_value) {
              await commonModel.putAlertsSettings(list.id, list.threshold_value)
            }
            // 設定値が削除されている場合は、DELETEする
            if (!list.threshold_value) {
              await commonModel.deleteAlertsSettings(list.id)
            }
          }
        }
      }

      // 通信異常の有無を判定
      const findAlert = this.exAlertsList.find(li => li.data_type_key == 'con')
      if (this.isAlertsConnect && !findAlert) {
        await commonModel.postAlertsSettings(this.currentBoxid, 'con', 0, false)
      } else if (!this.isAlertsConnect && findAlert && findAlert.id) {
        await commonModel.deleteAlertsSettings(findAlert.id)
      }

      commonModel.removeUserBoxesList();
      this.boxes = await commonModel.getBoxes(this.profile.user_id);
      this.changeBoxid()
      this.boxStatusText = 'BOX情報を更新しました。'
    }
  }

  private async saveMailInfo (): Promise<void> {
    if ((this.$refs.mailForm as Vue & { validate: () => boolean }).validate()) {
      this.mailStatusText = ''
      const exMailList = await commonModel.getAlertsRecipients(this.currentBoxid)

      for (let idx = 0; idx < this.mailList.length; idx++) {
        const currntMail = exMailList.find(li => li.id == this.mailList[idx].id)
        if (!this.mailList[idx].id) {
          // アドレスを新規作成
          await commonModel.postAlertsRecipients(this.currentBoxid, this.mailList[idx].email || '', this.mailList[idx].associated_setting_list || [])
        } else if (currntMail && (this.mailList[idx].associated_setting_list || []).toString() !== (currntMail.associated_setting_list || []).toString()) {
          // 配列が変更されているので、更新
          await commonModel.putAlertsRecipients(this.mailList[idx].id || 0, this.mailList[idx].email || '', this.mailList[idx].associated_setting_list || [])
        }
      }

      for (let idx = 0; idx < exMailList.length; idx++) {
        if (!this.mailList.some(li => li.id == exMailList[idx].id)) {
          // idが消えているので、アドレスを削除
          await commonModel.deleteAlertsRecipients(exMailList[idx].id || 0)
        }
      }
      this.mailStatusText = '警告の配信先を更新しました。'
    }
  }

  // private isNumber (newNumber:number|string): boolean {
  //   const num = String(newNumber)
  //   if (num == 'undefined' || num == '') {
  //     return false
  //   }
  //   // if (/^[-]?([1-9]\d*|0)(\.\d+)?$/.test(num)) {
  //   if (/^[-]?([0-9]*|0)(\.[0-9]+)?$/.test(num)) {
  //     return false
  //   }
  //   return true
  // }

  private isUsingBoxName (newName:string): boolean {
    return this.boxes.some((value:iBoxes) => value.name == newName && !(value.id == this.currentBoxid))
  }


}
