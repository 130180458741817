








import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class App extends Vue {

  private async mounted (): Promise<void> {
    // 画面サイズ変更時(回転など)に実行
    this.resizeWindow()
    window.addEventListener('resize', this.resizeWindow, false)
  }

  private resizeWindow (): void {
    // 主にiPhoneのsafariなどでアドレスバーが表示されているときに100vhが正しく機能しないので、v-application--wrapのmin-height: 100vh;を固定値にする
    let elem = document.querySelector<HTMLElement>('#app .v-application--wrap');
    if (elem) {
      elem.style.setProperty('min-height', window.innerHeight + 'px', '');
    }
  }
}
