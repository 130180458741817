
































import { Vue, Component, Watch } from 'vue-property-decorator'
// import _ from 'lodash'
import dayjs from 'dayjs'
import iChart from 'chart.js'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
import commonModel from '@/models/common'
import { iProfile, iBoxes, iBoxDataList, iEnvironment, iChartDataSet, iChartDataDetail, iDataList, iThiPercent, iProduction, iChartItems } from '@/@types/common';
Chart.register(zoomPlugin);

@Component({})
export default class LineChart extends Vue {

  public displayDate = ''
  public currentDate = dayjs().format('YYYY-MM-DD')
  public currentPeriod = 1
  public currentChartType = 'detail'
  public currentThiType = 'normal'
  public isDayApi:boolean[] = []
  private chartData: {datasets: iChartDataSet[]} = {datasets: []}
  private registeredDate:string[] = []
  private chartItems:iChartItems[] = []
  private boxes:iBoxes[] = [];
  private currentApiType = 'raw'
  private myChart!:Chart
  private myProfile:iProfile = {user_id:''};
  private dailySelectItem = 'average'
  private dailyItemList:{[key: string]: string;}[] = [{text: '平均', value: 'average'},{text: '最大', value: 'max'},{text: '最小', value: 'min'},{text: '合計', value: 'sum'}]


  // ----------------------------------------------------
  // Watch関数
  // ----------------------------------------------------
  @Watch('currentDate')
  async watchCurrentDate (newValue: string, oldValue: string): Promise<void> {
    if (oldValue && newValue) {
      const min:number = dayjs(this.currentDate).valueOf()
      const max:number = dayjs(this.currentDate).add(this.currentPeriod, 'day').valueOf()
      await this.loadCurrentDate(min, max)
      await this.myChartUpdate(min, max)
    }
  }

  @Watch('chartData')
  async watchChartData (newValue: iChart.ChartData): Promise<void> {
    if (newValue.datasets.length > 0) {
      this.myChart.data = newValue
      await this.myChartUpdate()
    }
  }

  @Watch('dailySelectItem')
  async watchDailySelectItem (newValue: string, oldValue: string): Promise<void> {
    if (oldValue && newValue) {
      this.reChart()
    }
  }

  @Watch('currentThiType')
  async watchCurrentThiType (newValue: string, oldValue: string): Promise<void> {
    if (oldValue && newValue) {
      this.reChart()
    }
  }

  // ----------------------------------------------------
  // Public関数
  // ----------------------------------------------------
  public async moveDays (course:string): Promise<void> {
    if (this.myChart.config.options && this.myChart.config.options.scales && this.myChart.config.options.scales.x) {
      const scalesX = this.myChart.config.options.scales.x
      if (course == 'prev') {
        scalesX.min = dayjs(scalesX.min).add(-this.currentPeriod, 'day').valueOf()
        scalesX.max = dayjs(scalesX.max).add(-this.currentPeriod, 'day').valueOf()
      } else if (course == 'next') {
        scalesX.min = dayjs(scalesX.min).add(this.currentPeriod, 'day').valueOf()
        scalesX.max = dayjs(scalesX.max).add(this.currentPeriod, 'day').valueOf()
      }

      if (scalesX.min && scalesX.max) {
        this.loadCurrentDate(scalesX.min, scalesX.max)
        await this.myChartUpdate()
      }
    }
  }

  public async changePeriod (days:number): Promise<void> {
    if (this.myChart && this.myChart.scales && this.myChart.scales.x && this.myChart.scales.x.max) {
      const refDate = dayjs(dayjs(this.myChart.scales.x.max).format('YYYY-MM-DD'))
      this.currentPeriod = days

      const min:number = refDate.add(-days, 'day').valueOf()
      const max:number = refDate.valueOf()

      await this.myChartUpdate(min, max)
      this.loadCurrentDate(min, max)
    }
  }

  public async reChart (): Promise<void> {
    await this.beforeMounted()
    if (!(this.currentChartType == localStorage.getItem('chartType'))) {
      this.currentChartType = localStorage.getItem('chartType') || this.currentChartType
      await this.afterMounted()
    }
    //this.changePeriod(this.currentPeriod) // これを入れて期間をリセットしないと、項目を切り替えたときにバグがでる
    await this.initialised()
  }

  // ----------------------------------------------------
  // グラフ描写用データの作成
  // ----------------------------------------------------
  private async loadCurrentDate (start:number, end:number): Promise<void> {
    if (this.isDayApi.length == 0) {
      await this.isDayApi.push(true)
      const dDtart = await dayjs(start)
      const dEnd = await dayjs(end)
      const diff = await dEnd.diff(dDtart, 'day') + 1
      await this.switchApiType(diff)

      let orderDateList:string[] = []
      for (let idx = 0; idx < diff; idx++) {
        // オーダーされた日付が取得済み配列に存在しなければ登録する
        const orderDate:string = await dDtart.add(idx, 'd').format('YYYY-MM-DD')
        if (!this.registeredDate.includes(orderDate)) {
          await orderDateList.push(orderDate)
          await this.registeredDate.push(orderDate)
        }
      }
      await this.addOrderDate(orderDateList)
      this.displayDate = dDtart.format('YYYY年MM月DD日')
      await this.isDayApi.pop()
    }
  }

  private async switchApiType (diff:number): Promise<void> {
    const switchValue = 15
    // 設定日よりdiffが大きい場合は、ApiTypeを切り替える
    if ((diff >= switchValue) && !(this.currentApiType == 'daily')) {
      this.currentApiType = 'daily'
      this.chartData.datasets = []
      this.registeredDate = []
    } else if ((diff < switchValue) && !(this.currentApiType == 'raw')) {
      this.currentApiType = 'raw'
      this.chartData.datasets = []
      this.registeredDate = []
    }
  }

  private async addOrderDate (orderDateList:string[]): Promise<void> {

      if (this.currentChartType == 'thiProd') {
        const thiPercent = await this.getThiPercent(orderDateList)
        const prodMilk = await this.getProdMilk(orderDateList)
        await this.addThiChartData(orderDateList, thiPercent, prodMilk)
      } else {
        await this.getDayData(orderDateList)
        // 過去と比較がONの場合は追加を行う
        const pastDay:number = parseInt(localStorage.getItem('pastDate') || '')
        if (pastDay) {
          await this.getDayData(orderDateList, pastDay)
        }
      }
      await this.myChartUpdate()
  }

  private async myChartUpdate (min = 0, max = 0): Promise<void> {
    if (this.myChart.config.options &&
        this.myChart.config.options.scales &&
        this.myChart.config.options.scales.x &&
        min !== 0 &&
        max !== 0)
    {
      const scalesX = this.myChart.config.options.scales.x
      scalesX.min = min
      scalesX.max = max
    }
    this.myChart.update();
  }

  // ----------------------------------------------------
  // THIと生乳生産量用データの作成
  // ----------------------------------------------------
  private addThiChartData (dateList:string[], thiPercents:iThiPercent[], prodMilks:iProduction[]): void {

    if (this.chartData.datasets.length == 0) {
      this.chartData.datasets.push(
        {id: JSON.stringify({dataTypeKey: 'prodMilk'}), type: 'line', label: '生乳生産量', data: [], backgroundColor: '#00a964', borderColor: '#00a964', yAxisID: 'yRight', pointRadius: 4, borderWidth: 0},
        {id: JSON.stringify({dataTypeKey: 'low'}), type: 'bar', label: '快適', data: [], backgroundColor: '#a9ceec', borderColor: '#a9ceec', yAxisID: 'y'},
        {id: JSON.stringify({dataTypeKey: 'middle'}), type: 'bar', label: '注意', data: [], backgroundColor: '#ffef6c', borderColor: '#ffef6c', yAxisID: 'y'},
        {id: JSON.stringify({dataTypeKey: 'high'}), type: 'bar', label: '危険', data: [], backgroundColor: '#f0b0b0', borderColor: '#f0b0b0', yAxisID: 'y'},
      )
    }

    for (let dIdx = 0; dIdx < dateList.length; dIdx++) {
      const thiPercent:iThiPercent = thiPercents.find(li => li.start_date == dateList[dIdx]) || {box_id:0}
      const prodMilk:iProduction = prodMilks.find(li => li.start_date == dateList[dIdx]) || {user_id:''}
      if (prodMilk.data_list && prodMilk.data_list[0]) {
        if (prodMilk.data_list[0].value) {this.chartData.datasets[0].data.push({x: dayjs(dateList[dIdx]).valueOf(), y: prodMilk.data_list[0].value})}
      }
      if (thiPercent.data_list && thiPercent.data_list[0]) {
        if (thiPercent.data_list[0].low) {this.chartData.datasets[1].data.push({x: dayjs(dateList[dIdx]).valueOf(), y: thiPercent.data_list[0].low})}
        if (thiPercent.data_list[0].middle) {this.chartData.datasets[2].data.push({x: dayjs(dateList[dIdx]).valueOf(), y: thiPercent.data_list[0].middle})}
        if (thiPercent.data_list[0].high) {this.chartData.datasets[3].data.push({x: dayjs(dateList[dIdx]).valueOf(), y: thiPercent.data_list[0].high})}
      }
    }
  }

  private async getProdMilk (dateList:string[]): Promise<iProduction[]> {
    let promiseArray = []
    for (let dIdx = 0; dIdx < dateList.length; dIdx++) {
      promiseArray.push(commonModel.getProduction(
        localStorage.getItem('currentUserId') || '',
        'milk',
        dateList[dIdx],
        dateList[dIdx],
        'daily'
      ))
    }
    const res:iProduction[] = await Promise.all(promiseArray)
    return res
  }

  private async getThiPercent (dateList:string[]): Promise<iThiPercent[]> {
    let promiseArray = []
    for (let dIdx = 0; dIdx < dateList.length; dIdx++) {
      promiseArray.push(commonModel.getThiPercent(
        Number(localStorage.getItem('currentBoxId')),
        dateList[dIdx],
        dateList[dIdx],
        this.currentThiType,
      ))
    }
    const res:iThiPercent[] = await Promise.all(promiseArray)
    return res
  }

  // ----------------------------------------------------
  // 通常グラフ用データの作成
  // ----------------------------------------------------
  private async getDayData (dateList:string[], pastDay = 0): Promise<void> {
    let envPromiseArray = []
    let prodPromiseArray = []
    for (let dIdx = 0; dIdx < dateList.length; dIdx++) {
      let date = dateList[dIdx]
      // 過去とのデータ比較がONの場合は日付を引く
      if (!(pastDay == 0)) {
        date = dayjs(date).add(-pastDay, 'day').format('YYYY-MM-DD')
      }
      for (let iIdx = 0; iIdx < this.chartItems.length; iIdx++) {

        if (this.chartItems[iIdx].apiType == 'environment') {
          // 計測値データ
          envPromiseArray.push(commonModel.getEnvironment(
            this.chartItems[iIdx].boxId || 0,
            this.chartItems[iIdx].dataTypeKey || '',
            date + ' 00:00:00',
            date + ' 23:59:59',
            this.currentApiType,
          ))
        } else if (this.chartItems[iIdx].apiType == 'production') {
          // 生育データ
          prodPromiseArray.push(commonModel.getProduction(
            this.chartItems[iIdx].userId || '',
            this.chartItems[iIdx].dataTypeKey || '',
            date,
            date,
            'daily'
          ))
        }
      }
    }

    const envRes:iEnvironment[] = await Promise.all(envPromiseArray)
    const prodRes:iProduction[] = await Promise.all(prodPromiseArray)
    await this.addEnvChartData(envRes, pastDay)
    await this.addProdChartData(prodRes, pastDay)
    await this.myChartUpdate()
  }

  private async addProdChartData (rowData:iProduction[], pastDay = 0): Promise<void> {
    for (let boxIdx = 0; boxIdx < rowData.length; boxIdx++) {

      // ラベルの作成
      const chartAttr = await this.createProdChartAttr(rowData[boxIdx], pastDay)

      // データリストの作成
      const list: iChartDataDetail[] = []
      const dataList:iDataList[] = rowData[boxIdx].data_list || []
      for (let dataIdx = 0; dataIdx < dataList.length; dataIdx++) {
        const value:number = (dataList[dataIdx].value || 0)
        await list.push({x: dayjs(dataList[dataIdx].date || 0).add(pastDay, 'day').valueOf(), y: Number(value.toFixed(2))})
      }

      let currentId = {
        userId: rowData[boxIdx].user_id,
        dataTypeKey: rowData[boxIdx].data_type_key,
        pastDay: pastDay,
      }
      await this.addChartData(JSON.stringify(currentId), chartAttr.type, chartAttr.label, list)
    }
  }

  private async createProdChartAttr (data:iProduction, pastDay:number): Promise<{label: string, type:string}> {
    let label = '';
    let type = '';

    const itemList:iBoxDataList[] = await commonModel.getProdItemList() || []
    const purposeItem:iBoxDataList = itemList.find(li => li.key == data.data_type_key) || {}

    // 標準ラベル
    type = purposeItem.type || ''
    label += purposeItem.name || ''
    label += '(' + (purposeItem.unit || '') + ')'

    // 管理者の場合はラベルにユーザー名も追加
    if (this.myProfile.admin_flg) {
      const currentProfile:iProfile = await commonModel.getUsersFromUserid(data.user_id) || {user_id:''}
      if (currentProfile.trade_name) {
        label += "-" + currentProfile.trade_name
      }
    }

    // 過去データを重ねている場合は何日前かのラベルを追加
    if (pastDay) {
      label += "-" + String(pastDay) + '日前'
    }

    return {label: label, type: type}
  }

  private async addEnvChartData (rowData:iEnvironment[], pastDay = 0): Promise<void> {
    for (let boxIdx = 0; boxIdx < rowData.length; boxIdx++) {
      // ラベルの作成
      const chartAttr = await this.createEnvChartAttr(rowData[boxIdx], pastDay)
      // データリストの作成
      const list = await this.createDataList(rowData[boxIdx], chartAttr.coeff, pastDay)

      let currentId = {
        boxId: rowData[boxIdx].box_id,
        dataTypeKey: rowData[boxIdx].data_type_key,
        pastDay: pastDay,
      }
      await this.addChartData(JSON.stringify(currentId), chartAttr.type, chartAttr.label, list)
    }
  }

  private async addChartData (idJson:string, type:string, label:string, list:iChartDataDetail[]): Promise<void> {
    // キーリストを作成して軸を決定する
    const yAxisID = await this.createYAxis(type)
    // 既に登録済みの列か調べる
    const findIdx:number = await this.chartData.datasets.findIndex(data => data.id == idJson)
    if (findIdx == -1) {
      // 初回データ登録
      await this.chartData.datasets.push({
        id: idJson,
        type: 'line',
        yAxisID: yAxisID,
        pointHitRadius: 30, // 当たり判定の大きさ
        pointRadius: 0, // 点の大きさ
        lineTension: 0, // ベジェ曲線 (0は直線)
        borderWidth: 2, // 線の太さ
        borderDash: [], // 破線設定
        fill: false, // 線の下を塗りつぶす
        label: label,
        data: list
      })
    } else {
      // 既に初回データが登録済みの場合は、既に存在する配列に結合する
      let beforeSorting: iChartDataDetail[] = await this.chartData.datasets[findIdx].data.concat(list);
      await beforeSorting.sort(function( a, b ){
        if( a.x < b.x ) return -1;
        if( a.x > b.x ) return 1;
        return 0;
      });
      this.chartData.datasets[findIdx].data = beforeSorting
    }
  }

  private async createEnvChartAttr (data:iEnvironment, pastDay:number): Promise<{label: string, coeff: number, type:string}> {
    let label = '';
    let type = '';
    let coeff = 1;
    const currentBox:iBoxes = this.boxes.find(box => box.id == data.box_id) || {id:0}
    if (currentBox.box_type) {
      const boxList:iBoxDataList[] = currentBox.box_type.data_list;
      if (boxList) {
        const sensor:iBoxDataList = boxList.find(sensor => sensor.key == data.data_type_key) || {digit: 0, id: 0, type: '', name: '', unit: ''}
        type = sensor.type || ''
        label += sensor.name
        if (sensor.unit) {
          label += '(' + sensor.unit + ')'
        }
        label += '-' + currentBox.name

        // ※ 全てを表示のCO2濃度は、×0.1をして値を整える
        if (sensor.key == "co2" && this.currentChartType == 'all') {
          label += " × 0.1"
          coeff = 0.1
        }

        // 管理者の場合はラベルにユーザー名も追加
        if (this.myProfile.admin_flg && currentBox.user_id) {
          const currentProfile:iProfile = await commonModel.getUsersFromUserid(currentBox.user_id) || {user_id:''}
          if (currentProfile.trade_name) {
            label += "-" + currentProfile.trade_name
          }
        }

        // 過去データを重ねている場合は何日前かのラベルを追加
        if (pastDay) {
          label += "-" + String(pastDay) + '日前'
        }

      }
    }
    return {label: label, coeff: coeff, type: type}
  }

  private async createYAxis (type:string): Promise<string> {
    let yAxisID = 'y'

    // 詳細表示の場合のみ、軸を分ける
    if (this.currentChartType == 'detail') {

      // キーリストを作成する
      let typeList:string[] = []
      for (let idx = 0; idx < this.chartItems.length; idx++) {
        if ('type' in this.chartItems[idx]) {
          const roopKey:string = this.chartItems[idx].type || ''
          if (typeList.indexOf(roopKey) == -1) {
            await typeList.push(roopKey)
          }
        }
      }
      // キーリストの2番目のキーの場合は右軸にする
      if (typeList.indexOf(type) == 1) {
        yAxisID = 'yRight'
      }

    }

    return yAxisID
  }

  private async createDataList (data:iEnvironment, coeff: number, pastDay:number): Promise<iChartDataDetail[]> {
    const list: iChartDataDetail[] = []
    const dataList:iDataList[] = data.data_list || []
    for (let dataIdx = 0; dataIdx < dataList.length; dataIdx++) {
      const data = dataList[dataIdx] as iDataList
      if (this.currentApiType == 'raw') {
        const value:number = (data.value || 0) * coeff
        let label = ''
        if (data.dirData) {
          label = commonModel.sortDirection(data.dirData.value || 0, value)
        }
        await list.push({
          x: dayjs(data.datetime || 0).add(pastDay, 'day').valueOf(),
          y: Number(value.toFixed(2)),
          label: label,
        })
      } else if (this.currentApiType == 'daily') {
        // TypeScriptの型エラー回避のために変な書き方になってしまっている
        for (const key in data) {
          if (key in data && this.dailySelectItem == key) {
            const value = Number(data[key as keyof iDataList]) * coeff
            let label = ''
            if (data.dirData) {
              const dir = Number(data.dirData[key as keyof iDataList])
              label = commonModel.sortDirection(dir, value)
            }
            await list.push({
              x: dayjs(data.date || 0).add(pastDay, 'day').valueOf(),
              y: Number(value.toFixed(2)),
              label: label,
            })
          }
        }
      }
    }
    return list
  }

  // ----------------------------------------------------
  // 初期読み込み関数
  // ----------------------------------------------------
  private async beforeMounted (): Promise<void> {
    // インスタンスを作成した後、イベントリスナに登録
    this.chartData.datasets = []
    this.registeredDate = []
    this.displayDate = dayjs(this.currentDate).format('YYYY年MM月DD日')

    if (localStorage.getItem('chartType') == 'thiProd') {
      // THIと生乳生産量を表示
    } else {
      // 通常グラフ
      this.chartItems =  await JSON.parse(localStorage.getItem('chartItems') || '[]')
      // 自分のBOX一覧を取得
      for (let idx = 0; idx < this.chartItems.length; idx++) {
        if ('boxId' in this.chartItems[idx]) {
          this.boxes.push(await commonModel.getBoxInfo(this.chartItems[idx].boxId || 0))
        }
      }
    }
  }

  private async afterMounted (): Promise<void> {
    if (this.myChart.config.options && this.myChart.config.options.scales) {
      const scales = this.myChart.config.options.scales

      if (scales.x && scales.y) {
        if (this.currentChartType == 'thiProd') {
          // THIと生乳生産量を表示
          if ('stacked' in scales.x) {scales.x.stacked = true}
          if ('stacked' in scales.y) {scales.y.stacked = true}
          if ('title' in scales.y && scales.y.title !== undefined) {scales.y.title.text = '不快指数(%)'}
          if (scales.yRight && 'title' in scales.yRight && scales.yRight.title !== undefined) {scales.yRight.title.text = '生乳生産量(kg)'}
          this.changePeriod(30)
        } else {
          this.changePeriod(1)
        }
      }
    }
  }

  private async initialised (): Promise<void> {

    if (this.myChart && this.myChart.config.options && this.myChart.config.options.scales) {
      const scales = this.myChart.config.options.scales
      for (let key in scales) {
        const scale = scales[key]
        // 列の表示を初期化
        if (scale && 'display' in scale && 'axis' in scale && scale.axis == 'y') {
          scale.display = false
        }
        // 列名を初期化
        if (scale && 'title' in scale) {
          const title = scale.title
          if (title && 'text' in title) {
            title.text = ''
          }
        }
      }

      if (scales.x && scales.y) {
        if (scales.x.min && scales.x.max) {
          this.loadCurrentDate(scales.x.min, scales.x.max)
        }

      }
    }
    this.myChartUpdate()
  }

  private async mounted (): Promise<void> {
    this.myProfile = await commonModel.getMyProfile()
    this.currentChartType = localStorage.getItem('chartType') || this.currentChartType
    await this.beforeMounted()
    // グラフを描写
    const canvas:HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('MainLineChart');
    const ctx = <CanvasRenderingContext2D>canvas.getContext('2d');

    this.myChart = new Chart(ctx, {
      type: 'line',
      data: this.chartData as iChart.ChartData,
      options: this.createChartOptions(),
      plugins: [{
        // ここで無色だったグラフに色を付けます
        id: 'addColorPlugin',
        beforeUpdate: (chart) => {
          const Tableau10 = ['#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948', '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC']

          let getIdx = 0;
          for (let idx = 0; idx < chart.config.data.datasets.length; idx++) {
            const dataset = chart.config.data.datasets[idx]

            // 過去のグラフを、現在のグラフと同じ色にする処理
            // ※ もし現在のグラフの着色前に過去のグラフを塗ろうとすると、エラーになる可能性がある
            if ('id' in dataset) {
              const dataInfo = JSON.parse(dataset['id'] || '[]')
              if (dataInfo.pastDay) {
                // 同じboxIdとdataTypeKeyのグラフを探す
                const fintData = chart.config.data.datasets.find(li => {
                  if ('id' in li) {
                    const currentInfo = JSON.parse(li['id'] || '[]')
                    return dataInfo.boxId == currentInfo.boxId && dataInfo.dataTypeKey == currentInfo.dataTypeKey
                  }
                }) || []
                if ('backgroundColor' in fintData && 'borderColor' in fintData) {
                  if (!('backgroundColor' in dataset) && !('borderColor' in dataset)) {
                    // 作成した変数「dataset」を使おうとするとTypeScriptのエラーが出る
                    chart.config.data.datasets[idx]['borderColor'] = fintData['borderColor']
                    chart.config.data.datasets[idx]['backgroundColor'] = fintData['backgroundColor']
                    chart.config.data.datasets[idx]['borderWidth'] = 1
                    if ('borderDash' in dataset) {
                      let borderDash:number[] = dataset['borderDash']
                      borderDash.push(5,5)
                    }
                  }
                }
                continue
              }
            }

            // グラフに色を着色する
            if (!dataset.backgroundColor && !dataset.borderColor) {
              dataset.backgroundColor = Tableau10[getIdx]
              dataset.borderColor = Tableau10[getIdx]
            }

            // getIdxが色配列数を上回っていたらリセット
            getIdx += 1
            if (getIdx >= Tableau10.length) {
              getIdx = 0
            }
          }
        }
      },{
        // ここでY軸名の有無や名前付けをします
        id: 'addLabelText',
        beforeUpdate: (chart) => {
          for (let idx = 0; idx < chart.data.datasets.length; idx++) {
            const data = chart.data.datasets[idx]
            if ('yAxisID' in data) {
              if (chart.config.options) {
                const scales = chart.config.options.scales
                const yAxisID: string = data.yAxisID || 'y'
                if (scales && scales[yAxisID]) {
                  // こちらで軸を有効化するか決定
                  const yAxis = scales[yAxisID] || {display: false}
                  if ('display' in yAxis) {
                    yAxis.display = true
                  }

                  // こちらで軸名を決定
                  let label = ''
                  if ('label' in data) {
                    const rawLabel:string = data.label || ''
                    label = rawLabel.split('-') [0]
                  }
                  if ('title' in yAxis) {
                    const title = yAxis.title || {text: ''}
                    if ('text' in title &&
                        title.text !== undefined &&
                        !title.text.includes(label) &&
                        this.currentChartType !== 'thiProd'
                    ) {
                      title.text += label + ' '
                    }
                  }

                }
              }
            }
          }
        }
      }]
    });

    await this.afterMounted()
    // 初期化
    await this.initialised()
  }

  private createChartOptions (): iChart.ChartOptions {
    return {
      animation: { duration: 0 },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: { stacked: false, type: 'time', time: { tooltipFormat:'yyyy-MM-dd HH:mm', displayFormats: { minute: 'H:mm', hour: 'H時', day: 'M月dd日', }, }, ticks: { maxRotation: 0, minRotation: 0, callback: function(value, idx, arr) {if (idx == 0) { return dayjs(arr[idx].value).format('M月D日') } else { return value } }, } },
        y: {      position: 'left',  display: false, ticks: {mirror: true, padding: 0, z: 10}, title: {text: '', display: true, padding: {top: 10, bottom: 0}}, stacked: false, suggestedMin: 0, },
        yRight: { position: 'right', display: false, ticks: {mirror: true, padding: 0, z: 10}, title: {text: '', display: true, padding: {top: 10, bottom: 0}}, stacked: false, suggestedMin: 0, },
      },
      plugins: {
        legend: {labels: {font: {size: 14}}},
        zoom: {
          pan: { enabled: true, mode: 'xy', overScaleMode: 'y', onPanComplete: (self) => { this.loadCurrentDate(self.chart.scales.x.min, self.chart.scales.x.max) }, },
          zoom: { wheel: {enabled: true,}, pinch: {enabled: true}, mode: 'xy', overScaleMode: 'y', onZoomComplete: (self) => { this.loadCurrentDate(self.chart.scales.x.min, self.chart.scales.x.max) }, }
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              // 過去データの場合は、日付分のラベルの日付をずらす
              let moveDay = 0
              if (context[0] && context[0].dataset && 'id' in context[0].dataset) {
                const dataInfo = JSON.parse(context[0].dataset['id'] || '[]')
                if (dataInfo.pastDay) {
                  moveDay = dataInfo.pastDay
                }
              }

              const title = dayjs(context[0].label).add(-moveDay, 'day').format('YYYY-MM-DD HH:mm') || ''
              return title
            },
            label: (context) => {
              // ラベルの設定
              let label = ''
              label += context.dataset.label || ''
              label += ' : '

              // データにラベルが存在する場合は、値と一緒に表示する(風向表示などの対応)
              const raw = context.raw as iChartDataDetail
              label += (raw.label || '') + ' '

              label += context.formattedValue || ''
              return label
            }
          }
        },
      }
    }
  }
}
