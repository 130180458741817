




























import { Vue, Component } from 'vue-property-decorator'
import commonModel from '@/models/common'
import Menu from '@/components/Menu.vue'
import LineChart from '@/components/LineChart.vue'
import SelectChartItems from '@/components/SelectChartItems.vue'

@Component({
  components: {
    Menu,
    LineChart,
    SelectChartItems,
  }
})
export default class Monitor extends Vue {
  private chartData = {};
  private version = '';
  private isLoaded = false;

  $refs!: {
    LineChart: LineChart
  }


  private async created (): Promise<void> {

    this.version = commonModel.getSetting().version;

    this.chartData = {
      datasets: [{
        type: 'line',
        yAxisID: 'y-axis-0',
        pointHitRadius: 20, // 当たり判定の大きさ
        pointRadius: 0, // 点の大きさ
        lineTension: 0, // ベジェ曲線 (0は直線)
        fill: false, // 線の下を塗りつぶす
        borderDash: [1, 0], // 破線の設定
        borderWidth: 2, // 線の太さ(px)
        borderColor: '#1f77b4',
        backgroundColor: '#1f77b4',
        label: 'テストBOX',
        data: [
          {'x': '2021-05-24 19:00:00', 'y': 20},
          {'x': '2021-05-24 20:00:00', 'y': 22},
          {'x': '2021-05-24 21:00:00', 'y': 25},
          {'x': '2021-05-24 22:00:00', 'y': 23}
        ],
      }]
    };
  }

  private async mounted (): Promise<void> {
    this.isLoaded = true
  }

}
