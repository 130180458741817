var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"BatteryIcon"}},[(_vm.currentPercent)?_c('span',[(_vm.dayjs().diff(_vm.dayjs(_vm.updateTime), "minute") < 30)?_c('span',[(_vm.currentPercent >= 80)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#689f38"}},[_vm._v("mdi-battery-charging-wireless-90")])],1)]}}],null,false,332801625)},[_c('span',[_vm._v("通信状態 : オンライン"),_c('br'),_vm._v("電池残量 : 約100%～80%")])]):(_vm.currentPercent < 80 && _vm.currentPercent >= 50)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#689f38"}},[_vm._v("mdi-battery-charging-wireless-60")])],1)]}}],null,false,239433782)},[_c('span',[_vm._v("通信状態 : オンライン"),_c('br'),_vm._v("電池残量 : 約80%～50%")])]):(_vm.currentPercent < 50 && _vm.currentPercent >= 25)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#f6901d"}},[_vm._v("mdi-battery-charging-wireless-20")])],1)]}}],null,false,1280773604)},[_c('span',[_vm._v("通信状態 : オンライン"),_c('br'),_vm._v("電池残量 : 約50%～25%")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#ff0000"}},[_vm._v("mdi-battery-charging-wireless-outline")])],1)]}}],null,false,2888979242)},[_c('span',[_vm._v("通信状態 : オンライン"),_c('br'),_vm._v("電池残量 : 約25%～0%")])])],1):_c('span',[(_vm.currentPercent >= 80)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-battery-90")])],1)]}}],null,false,2991298145)},[_c('span',[_vm._v("通信状態 : オフライン"),_c('br'),_vm._v("電池残量 : 約100%～80%")])]):(_vm.currentPercent < 80 && _vm.currentPercent >= 50)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-battery-60")])],1)]}}],null,false,2035404750)},[_c('span',[_vm._v("通信状態 : オフライン"),_c('br'),_vm._v("電池残量 : 約80%～50%")])]):(_vm.currentPercent < 50 && _vm.currentPercent >= 25)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-battery-20")])],1)]}}],null,false,2761473354)},[_c('span',[_vm._v("通信状態 : オフライン"),_c('br'),_vm._v("電池残量 : 約50%～25%")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#ff0000"}},[_vm._v("mdi-battery-off-outline")])],1)]}}],null,false,3704716759)},[_c('span',[_vm._v("通信状態 : オフライン"),_c('br'),_vm._v("電池残量 : 約25%～0%")])])],1)]):_c('span',[(_vm.dayjs().diff(_vm.dayjs(_vm.updateTime), "minute") < 30)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#689f38"}},[_vm._v("mdi-signal")])],1)]}}],null,false,3984065617)},[_c('span',[_vm._v("通信状態 : オンライン")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-signal-off")])],1)]}}])},[_c('span',[_vm._v("通信状態 : オフライン")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }