


































































import { Vue, Component } from 'vue-property-decorator'
import commonModel from '@/models/common'
import Menu from '@/components/Menu.vue'

@Component({
  components: {
    Menu
  }
})
export default class Password extends Vue {

  private statusText = '';
  private valid = true;
  private passList = {
    old: {text: '', isShow: false},
    new: {text: '', isShow: false},
    reNew: {text: '', isShow: false}
  };
  private rules = {
    required: ((value:string) => !!value || '必須項目です'),
    min: ((value:string) => value.length >= 8 || '半角英数字記号を8文字以上入力してください'),
    match: (() => this.passList.new.text == this.passList.reNew.text || '新しいパスワードが一致しません'),
    charCheck: ((value:string) => value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) || '半角英数字記号で入力してください'),
    same: (() => !(this.passList.old.text == this.passList.new.text) || '前回と同じパスワードは使用できません'),
  };

  private async created (): Promise<void> {
    this.statusText = ''
  }

  private async validate (): Promise<void> {
    if ((this.$refs.passForm as Vue & { validate: () => boolean }).validate()) {
      if (this.passList.new.text == this.passList.reNew.text) {
        await commonModel.postChangePassword(this.passList.old.text, this.passList.new.text).then(() => {
          this.statusText = 'パスワードを更新しました。'
        })
        .catch(() => {
          this.statusText = 'ユーザー情報の更新に失敗しました。現在のパスワードを確認してください。'
        });
      } else {
        this.statusText = '「新しいパスワード」と「新しいパスワード(再入力)」が一致しません。'
      }
    }
  }


}
