



























































































import { Component, Vue, Prop } from 'vue-property-decorator'
import dayjs from 'dayjs'
import commonModel from '@/models/common'
import { iBoxes, iProfile} from '@/@types/common';

@Component({
})
export default class DownloadDialog extends Vue {

  @Prop({required: true}) private type!: string;
  private showDialog = false;
  private showDatepicker = false;
  private currentUserId = ''
  private currentBoxId = 0
  private selectSpan = 1
  private dataType = 'raw'
  private dataTypeList: {name:string, value:string}[] = [{name: '生データ', value: 'raw'}, {name: '集計データ', value: 'daily'}]
  public currentDate = dayjs().format('YYYY-MM-DD')
  private groupMembers:iProfile[] = []
  private boxes:iBoxes[] = []


  private async mounted (): Promise<void> {
    // 現在のアカウントで取得できるBOX一覧を取得する
    const profile:iProfile = await commonModel.getMyProfile();
    // 管理者の場合
    if (profile.admin_flg) {
      this.groupMembers = await commonModel.getGroupMembres();
    } else {
      this.groupMembers = [profile]
    }
    this.currentUserId = this.groupMembers[0].user_id
    this.changeUserId()
  }

  private async changeUserId (): Promise<void> {
    this.boxes = await commonModel.getBoxes(this.currentUserId)
    this.currentBoxId = this.boxes[0].id
  }

  private async downloadCsv (): Promise<void> {
    let dataText = ''
    let fileName = ''
    if (this.type == 'environment') {
      dataText = await commonModel.getEnvDownload(
        this.currentBoxId,
        this.currentDate,
        dayjs(this.currentDate).add(this.selectSpan, 'day').format('YYYY-MM-DD'),
        this.dataType,
      )
      const boxName:string = (this.boxes.find(li => li.id == this.currentBoxId) || {name:''}).name || ''
      const typeName:string = (this.dataTypeList.find(li => li.value == this.dataType) || {name:''}).name || ''
      fileName = boxName + '_' + typeName + '.csv'
    } else if (this.type == 'production') {
      dataText = await commonModel.getProdDownload(
        this.currentUserId,
        this.currentDate,
        dayjs(this.currentDate).add(this.selectSpan, 'day').format('YYYY-MM-DD'),
      )
      fileName = '生産量データ_' + this.currentUserId + '.csv'
    }

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, dataText], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    this.showDialog = false
  }
}
