


































import { Vue, Component } from 'vue-property-decorator'
import commonModel from '@/models/common'
import axios, {  AxiosResponse } from 'axios'

@Component({
  components: {
  }
})
export default class Login extends Vue {
  private userId = '';
  private password = '';
  private errText = '';
  private version = '';
  private valid = true;
  private showPass = false;
  private rules = {
    required: ((value:string) => !!value || '必須項目です'),
    min: ((value:string) => value.length >= 8 || '半角英数字記号を8文字以上入力してください'),
    charCheck: ((value:string) => value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) || '半角英数字記号で入力してください'),
  };

  private async mounted (): Promise<void> {
    let set = commonModel.getSetting();
    this.version = set.version;
    // トークンが存在していればリロードする
    // ※ このタイミングで一度リロードしないと、cookiesを削除してもブラウザに残る
    const apiToken: boolean = this.$cookies.isKey('farmlog_api_token');
    if (apiToken) {
      location.reload()
    }
    // トークンを削除する
    this.$cookies.remove('farmlog_api_token')
    // セッションストレージとローカルストレージをクリアする
    sessionStorage.clear()
    localStorage.clear()
  }

  private async validate (): Promise<void> {
    let apiToken = ''
    this.errText = ''
    let set = commonModel.getSetting();
    const config = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    let params = new URLSearchParams();
    params.append('username', this.userId);
    params.append('password', this.password);
    try {
      const response:AxiosResponse = await axios.post(set.apiUrl + '/token', params, config)
      apiToken = response.data.access_token
      // cookiesを保存
      this.$cookies.set('farmlog_api_token', apiToken)
      // セッションストレージを保存する(旧画面用)
      sessionStorage['actoken'] = apiToken;
      const profile = await commonModel.getMyProfile();
      sessionStorage['userinfo'] = JSON.stringify(profile);

      this.$router.push('/')
      // this.$router.back()
    } catch (error) {
      this.errText = 'ユーザーID又はパスワードが間違っています'
    }
  }

}
