import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueCookies from 'vue-cookies'
import axios from 'axios'

Vue.use(vueCookies)
Vue.config.productionTip = false

// API通信時にデフォルトでトークンを挿入する
axios.interceptors.request.use(config => {
  if (!config['headers']['Authorization']) {
    const apiToken: string = Vue.$cookies.get('farmlog_api_token');
    config['headers']['Authorization'] = 'Bearer ' + apiToken;
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // API通信時に401エラー(認証エラー)の場合は、ログアウト画面へ飛ばす
    // ログイン画面は例外とする
    // パスワード変更画面は例外とする
    if (error.response.status == 401 && error.response.config.url.indexOf('/token') == -1 && error.response.config.url.indexOf('/change') == -1) {
      // window.location.href = '/login';
      router.push('/login')
    }
    return Promise.reject(error)
  }
);

// Vue Routerでのページ切替時に実行される
router.beforeEach((to, from, next) => {

  const apiToken:string = Vue.$cookies.get('farmlog_api_token');
  // トークンが存在するか判定して、存在しなければログイン画面へ飛ばす
  if (to.matched.some(page => page.meta.isPublic) || apiToken) {
    next()
  } else {
    next('/login')
  }

  // レスポンシブ対応のページか判定してviewportを切り替える
  const elem = <HTMLMetaElement>document.querySelector("meta[name='viewport']")
  if (to.matched.some(record => record.meta.responsive)) {
    elem.setAttribute('content', 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=yes')
    next()
  } else {
    elem.setAttribute('content', 'width=1280, user-scalable=yes')
    next()
  }

})

// エラー処理
const errText = 'エラーが発生しました。画面のリロードや再度ログインを試してみてください。\n\n'
Vue.config.errorHandler = (err) => {
  console.log('Captured in Vue.config.errorHandler: ${info}', err)
  alert('Captured in Vue.config.errorHandler\n\n' + errText + err)
};
window.addEventListener('error', event => {
  console.log('Captured in error EventListener', event.error)
  alert('Captured in error EventListener\n\n' + errText + event.error)
});
window.addEventListener('unhandledrejection', event => {
  // ローカルストレージがいっぱいの場合は、ローカルストレージをクリアして解決するのでエラーを表示しない
  if (event.reason.name == 'QuotaExceededError') {
    localStorage.clear()
    return
  }
  console.log('Captured in unhandledrejection EventListener', event.reason)
  alert('Captured in unhandledrejection EventListener\n\n' + errText + event.reason)
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
