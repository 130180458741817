






















































import { Component, Vue, Watch } from 'vue-property-decorator'
import commonModel from '@/models/common'
import { iBoxes, iProfile, iChartItems } from '@/@types/common';

@Component({})
export default class MSelectChartItems extends Vue {

  private parent = this.$parent.$data
  private nowTabs = 0
  private selects:string[] = []
  private selectedTypes:string[] = []
  private allUserBoxes:{
    userId: string,
    userName: string,
    boxes: iBoxes[],
  }[] = []

  private async selectSensor (): Promise<void> {
    // 選択済みのセンサーキーをリスト化する
    this.selectedTypes = []
    for (let idx = 0; idx < this.selects.length; idx++) {
      const type = this.selects[idx].split('-')[2]
      if (this.selectedTypes.indexOf(type) == -1) {
        this.selectedTypes.push(type)
      }
    }
  }

  private async mounted (): Promise<void> {
    // 既に選択済みにチェックを行う
    const chartItems:iChartItems[] =  JSON.parse(localStorage.getItem('chartItems') || '[]')
    for (let idx = 0; idx < chartItems.length; idx++) {
      this.selects.push(String(chartItems[idx].boxId) + '-' + chartItems[idx].dataTypeKey + '-' + chartItems[idx].type)
    }

    // 現在のアカウントで取得できるBOX一覧を取得する
    const profile:iProfile = await commonModel.getMyProfile();
    // 管理者の場合
    if (profile.admin_flg) {
      const groupMembers:iProfile[] = await commonModel.getGroupMembres();
      let promiseArray = []
      for (let idx = 0; idx < groupMembers.length; idx++) {
        promiseArray.push(commonModel.getBoxes(groupMembers[idx].user_id))
      }
      // メンバー一覧からBOX一覧を取得
      const boxes:iBoxes[][] = await Promise.all(promiseArray)
      for (let idx = 0; idx < boxes.length; idx++) {
        if (!(boxes[idx].length == 0)) {
          const userInfo:iProfile = await commonModel.getUsersFromUserid(boxes[idx][0].user_id || '')
          this.allUserBoxes.push({
            userId: userInfo.user_id || '',
            userName: userInfo.user_name || '',
            boxes: boxes[idx],
          })
        }
      }
    } else {
      const boxes:iBoxes[] = await commonModel.getBoxes(profile.user_id)
      this.allUserBoxes.push({
        userId: profile.user_id || '',
        userName: profile.user_name || '',
        boxes: boxes,
      })
    }

    this.nowTabs = this.allUserBoxes.findIndex(li => li.userId == localStorage.getItem('currentUserId')) || 0
    this.selectSensor()
  }

  @Watch('parent.showSelectChart')
  async watchShowSelectChart (newValue: boolean, oldValue: boolean): Promise<void> {
    if (newValue == false && oldValue == true) {
      this.decisionBtn()
    }
  }

  private decisionBtn (): void {
    let chartItems: iChartItems[] = []
    for (let idx = 0; idx < this.selects.length; idx++) {
      const splitItem = this.selects[idx].split('-')
      chartItems.push({
        boxId: parseInt(splitItem[0]),
        dataTypeKey: splitItem[1],
        type: splitItem[2],
        apiType: 'environment',
      })
    }
    localStorage.setItem('chartItems', JSON.stringify(chartItems))
    localStorage.setItem('chartType', 'detail')
    location.reload()
  }
}
