



























































import { Vue, Component } from 'vue-property-decorator'
import dayjs from 'dayjs'
import LineChart from '@/components/LineChart.vue'
import MSelectChartItems from '@/components/MSelectChartItems.vue'
// import commonModel from '@/models/common'
// import { iBoxes, iBoxDataList, iEnvironment, iChartDataSet, iChartDataDetail, iDataList, iThiPercent, iProduction } from '@/@types/common';
dayjs.locale('ja')

@Component({
  components: {
    LineChart,
    MSelectChartItems
  }
})
export default class MainGraph extends Vue {

  private localStorage = localStorage
  private loadedLineChart = false
  private showDatepicker = false
  private showSelectChart = false

  $refs!: {
    LineChart: LineChart
  }


  private async mounted (): Promise<void> {
    this.loadedLineChart = true

    // モバイル版は過去との比較に対応していないのでOFFにする
    localStorage.setItem('pastDate', '0')
  }

}
