


























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import dayjs from 'dayjs'

@Component({
  components: {
  }
})
export default class BatteryIcon extends Vue {
  private dayjs = dayjs;
  @Prop({required: true}) private updateTime!: string;
  @Prop({required: true}) private currentPercent!: string;
}
