










































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import commonModel from '@/models/common'
import { iProfile, iProduction, iDataList } from '@/@types/common';
import dayjs from 'dayjs'
import Menu from '@/components/Menu.vue'
import DownloadDialog from '@/components/DownloadDialog.vue'

@Component({
  components: {
    Menu,
    DownloadDialog,
  }
})
export default class Production extends Vue {

  private isDayApi:boolean[] = []
  private dayjs = dayjs;
  private isShowVerificat = false;
  private inputForm = false;
  private csvUploadStatus = {isShow: false, errMessage: '', value: ''};
  private csvFile:FormData = new FormData();
  private inputDate = {isShow: false, value: dayjs().format('YYYY-MM-DD')};
  private groupMembers:iProfile[] = [];
  private myProfile:iProfile = {user_id:''};
  private currentUserId = '';
  private dailyProductionData:{userId: string, tradeName: string, managementNumber:number, dataList: {[key: string]: iDataList[]}}[] = []
  private monthProductionList:iProduction[] = [];
  private yearProductionList:iProduction[] = [];
  private dailyDate = {start: dayjs().format('YYYY-MM-01'), end: dayjs().endOf('month').format('YYYY-MM-DD')};
  private monthlyDate = {start: dayjs().format('YYYY-01-01'), end: dayjs().endOf('year').format('YYYY-MM-DD'), select: 'average'};
  private columnList:{[key: string]: string;}[] = []
  private ruleNumber = (v:string) => {if (v) return /^([-]?([0-9]*|0)(\.[0-9]+)?)?$/.test(String(v)) || '数値で入力してください'; else return true}


  @Watch('inputDate.value')
  @Watch('currentUserId')
  async watchInputDate (): Promise<void> {
    this.changeInputDate(this.inputDate.value)
  }

  private async created (): Promise<void> {

    // 項目リストを作成する
    const prodItemList = await commonModel.getProdItemList()
    for (let iIdx = 0; iIdx < prodItemList.length; iIdx++) {
      this.columnList.push({
        name: (prodItemList[iIdx].name || '') + '(' + (prodItemList[iIdx].unit || '') + ')',
        key: prodItemList[iIdx].key || '',
        value: ''
      })
    }

    // 現在のアカウントで取得できるBOX一覧を取得する
    this.myProfile = await commonModel.getMyProfile();
    if (this.myProfile.admin_flg) {
      const membres = await commonModel.getGroupMembres();
      this.groupMembers = membres
    } else {
      this.groupMembers = [this.myProfile]
    }

    this.currentUserId = this.groupMembers[0].user_id
    // ローカルストレージにユーザ情報が存在する場合はそのユーザを使用する
    const localUserId:string = localStorage.getItem('currentUserId') || ''
    if (localUserId && this.groupMembers.some(li => li.user_id == localUserId)) {
      this.currentUserId = localUserId
    }
    localStorage.setItem('currentUserId', this.currentUserId)

    this.changeInputDate(this.inputDate.value)
    this.loadDailyProduction()
    this.loadMonthlyProduction()
  }

  private async postCsvData (): Promise<void> {
    this.isDayApi.push(true)
    commonModel.postProductionCsv(this.myProfile.user_id, this.csvFile).then(() => {
      this.csvUploadStatus.value = ''
      this.csvUploadStatus.errMessage = ''
      this.csvUploadStatus.isShow = false
      this.loadDailyProduction()
      this.loadMonthlyProduction()
    }).catch(() => {
      this.csvUploadStatus.value = ''
      this.csvUploadStatus.errMessage = 'CSVファイルの登録に失敗しました'
    }).finally(() => {
      this.isDayApi.pop()
    });

  }

  private async loadCsvData (file:File): Promise<void> {
    if (file == null) {
      this.csvUploadStatus.value = ''
      this.csvUploadStatus.errMessage = ''
      return;
    }

    this.isDayApi.push(true)
    this.readFileAsync(file).then((result) => {
      this.csvUploadStatus.value = String(result)
      this.csvUploadStatus.errMessage = ''
      this.csvFile = new FormData();
      this.csvFile.append('csv_file', file);
    }).catch(() => {
      this.csvUploadStatus.value = ''
      this.csvUploadStatus.errMessage = 'CSVファイルの読み込みに失敗しました'
    }).finally(() => {
      this.isDayApi.pop()
    })
  }

  private async readFileAsync (file:File): Promise<string|ArrayBuffer|null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file, 'shift-jis');
    });
  }

  private async registerProductionData (): Promise<void> {
    this.isDayApi.push(true)

    let promiseArray = []
    for (let idx = 0; idx < this.columnList.length; idx++) {
      let value:number|undefined = undefined
      if (!(this.columnList[idx].value == '')) {
        value = Number(this.columnList[idx].value)
      }

      promiseArray.push(commonModel.putProduction(
        this.currentUserId,
        value,
        this.inputDate.value,
        this.columnList[idx].key,
        'daily'
      ))
    }
    await Promise.all(promiseArray)

    this.loadDailyProduction()
    this.loadMonthlyProduction()
    this.isShowVerificat = false
    this.isDayApi.pop()
  }


  // ここからmonthlyデータ用
  private async loadMonthlyProduction (): Promise<void> {
    this.isDayApi.push(true)
    let promiseArray = []
    for (let uIdx = 0; uIdx < this.groupMembers.length; uIdx++) {
      for (let cIdx = 0; cIdx < this.columnList.length; cIdx++) {
        promiseArray.push(commonModel.getProduction(
          this.groupMembers[uIdx].user_id,
          this.columnList[cIdx].key,
          this.monthlyDate.start,
          this.monthlyDate.end,
          'monthly'
        ))
      }
    }
    this.yearProductionList = await Promise.all(promiseArray)
    this.isDayApi.pop()
  }

  private async moveMonthly (move:number): Promise<void> {
    const day = dayjs(this.monthlyDate.start).add(move, 'year')
    this.monthlyDate.start = day.format('YYYY-MM-01')
    this.monthlyDate.end = day.endOf('year').format('YYYY-MM-DD')
    this.loadMonthlyProduction()
  }

  private findMonthlyProductionData (userid:string, key:string, findDate:string): iDataList {
    const findList:iProduction = this.yearProductionList.find(li => li.user_id == userid && li.data_type_key == key) || {user_id: ''}
    if (findList.data_list && findList.data_list.length !== 0) {
      const findData:iDataList = findList.data_list.find(li => li.date == findDate) || {}
      return findData
    }
    return {}
  }

  private findCalcMonthly (userid:string, key:string): iDataList[] {
    const findList:iProduction = this.yearProductionList.find(li => li.user_id == userid && li.data_type_key == key) || {user_id: ''}
    if (findList.data_list && findList.data_list.length !== 0) {
      return findList.data_list
    }
    return []
  }


  // ここからdailyデータ用
  private async loadDailyProduction (): Promise<void> {
    this.isDayApi.push(true)
    let promiseArray = []
    for (let uIdx = 0; uIdx < this.groupMembers.length; uIdx++) {
      for (let cIdx = 0; cIdx < this.columnList.length; cIdx++) {
        promiseArray.push(commonModel.getProduction(
          this.groupMembers[uIdx].user_id,
          this.columnList[cIdx].key,
          this.dailyDate.start,
          this.dailyDate.end,
          'daily'
        ))
      }
    }
    this.monthProductionList = await Promise.all(promiseArray)
    await this.processDailyProductionData()
    this.isDayApi.pop()
  }

  private async moveDaily (move:number): Promise<void> {
    const day = dayjs(this.dailyDate.start).add(move, 'month')
    this.dailyDate.start = day.format('YYYY-MM-01')
    this.dailyDate.end = day.endOf('month').format('YYYY-MM-DD')
    this.loadDailyProduction()
  }

  private calcDaily (userid:string, key:string, type:string): string {
    let total = 0
    let count = 0

    const findList:iProduction = this.monthProductionList.find(li => li.user_id == userid && li.data_type_key == key) || {user_id: ''}
    if (findList.data_list && findList.data_list.length !== 0) {
      for (let idx = 0; idx < findList.data_list.length; idx++) {
        const data =  findList.data_list[idx]
        if (data.value) {
          total += data.value
          count += 1
        }
      }
    }

    if (type == 'sum' && total !== 0) {
      return String(total.toFixed(0))
    } else if (type == 'avg') {
      const avg = (total / count)
      if (avg) {return avg.toFixed(0)}
    }
    return ''
  }

  private getDailyItemStyle (userId:string): string {
    // 未入力の場合は色変更無し
    if (userId == '') {
      return ''
    }
    // 管理者は白、生産者は緑に色変更
    if (this.myProfile.admin_flg) {
      // 管理者の場合は、作成者が自分ではない場合は色変更
      if (!(this.myProfile.user_id == userId)) {
        return 'background: #C0EDC0;'
      }
    } else {
      // 管理者ではない場合は、作成者が自分の場合は色変更
      if (this.myProfile.user_id == userId) {
        return 'background: #C0EDC5;'
      }
    }
    return ''
  }

  private async processDailyProductionData (): Promise<void> {
    this.dailyProductionData = []
    for (let gIdx = 0; gIdx < this.groupMembers.length; gIdx++) {
      let columnDataList:{[key: string]: iDataList[]} = {}
      for (let cIdx = 0; cIdx < this.columnList.length; cIdx++) {
        const dataValueList:iDataList[] = []
        const findList:iProduction = this.monthProductionList.find(li => li.user_id == this.groupMembers[gIdx].user_id && li.data_type_key == this.columnList[cIdx].key) || {user_id: ''}
        if (findList.data_list) {
          const dDtart = dayjs(findList.start_date)
          const diff = dayjs(findList.end_date).diff(dDtart, 'day') + 1
          for (let idx = 0; idx < diff; idx++) {
            const findDate:string = dDtart.add(idx, 'd').format('YYYY-MM-DD')
            const findData:iDataList = findList.data_list.find(li => li.date == findDate) || {}
            if ('value' in findData && 'updated_by' in findData) {
              dataValueList.push({date: findDate, updated_by: findData.updated_by, value: findData.value})
            } else {
              dataValueList.push({date: findDate, updated_by: '', value: undefined})
            }
          }
        }
        columnDataList[this.columnList[cIdx].key] = dataValueList
      }

      this.dailyProductionData.push({
        userId: this.groupMembers[gIdx].user_id,
        tradeName: this.groupMembers[gIdx].trade_name || '',
        managementNumber: this.groupMembers[gIdx].management_number || 0,
        dataList: columnDataList,
      })
    }
  }

  private async changeInputDate (date:string): Promise<void> {
    // 変更した日付のデータを読み込む
    let promiseArray = []
    for (let idx = 0; idx < this.columnList.length; idx++) {
      promiseArray.push(commonModel.getProduction(
        this.currentUserId,
        this.columnList[idx].key,
        date,
        date,
        'daily'
      ))
    }
    const currentProdDataList = await Promise.all(promiseArray)
    for (let idx = 0; idx < this.columnList.length; idx++) {
      const data = currentProdDataList.find(li => li.data_type_key == this.columnList[idx].key) || {data_list: []}
      if (data.data_list && data.data_list.length > 0) {
        this.columnList[idx]['value'] = String(data.data_list[0].value)
      } else {
        this.columnList[idx]['value'] = ''
      }
    }
  }

  private isNumber (newNumber:number|string): boolean {
    const num = String(newNumber)
    if (num == 'undefined' || num == '') {
      return false
    }
    if (/^[-]?([1-9]\d*|0)$/.test(num)) {
      return false
    }
    return true
  }



}
