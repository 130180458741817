







































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import commonModel from '@/models/common'
import { iBoxes, iProfile, iBoxDataList, iLatest, iBattPercent, iChartItems } from '@/@types/common';
import BatteryIcon from '@/components/BatteryIcon.vue'
import DownloadDialog from '@/components/DownloadDialog.vue'

@Component({
  components: {
    BatteryIcon,
    DownloadDialog,
  }
})
export default class SelectChartItems extends Vue {

  private dayjs = dayjs;
  private commonModel = commonModel;
  private currentUserId = ''
  private currentPastDate = 0
  private currentPeriod = 1
  private showDatepicker = false
  private isCompareWithPast = false
  private groupMembers:iProfile[] = []
  private boxes:iBoxes[] = []
  private latestList:iLatest[] = []
  private battPercentList:iBattPercent[] = []
  private selects:string[] = []
  private selectedTypes:string[] = []
  private prodItemList:iBoxDataList[] = []

  @Watch('currentPeriod')
  async watchCurrentDate (newValue: string, oldValue: string): Promise<void> {
    if (oldValue && newValue) {
      this.$emit('changePeriod', newValue)
    }
  }

  private async clickThiAndProduct (boxId:number): Promise<void> {
    this.selects = [];
    this.currentPeriod = 30
    this.selectSensor()
    localStorage.setItem('currentBoxId', String(boxId))
    localStorage.setItem('currentUserId', this.currentUserId)
    localStorage.setItem('chartType', 'thiProd')
    // location.reload()
    this.$emit('reChart')
  }

  private async restrictSelectSensor (): Promise<void> {
    // 選択済みのセンサーキーをリスト化する(2つ以上のキーが選択されないように)
    this.selectedTypes = []
    for (let idx = 0; idx < this.selects.length; idx++) {
      const list = JSON.parse(this.selects[idx])
      if ('type' in list && this.selectedTypes.indexOf(list.type) == -1) {
        this.selectedTypes.push(list.type)
      }
    }
  }

  private async selectSensor (): Promise<void> {
    this.restrictSelectSensor()
    // 選択を確定して、ローカルストレージに書込む
    let chartItems: iChartItems[] = []
    for (let idx = 0; idx < this.selects.length; idx++) {
      chartItems.push(JSON.parse(this.selects[idx]))
    }
    localStorage.setItem('chartItems', JSON.stringify(chartItems))
    localStorage.setItem('chartType', 'detail')
    // this.$parent.$refs.LineChart.reChart()
    this.$emit('reChart')
  }

  private async mounted (): Promise<void> {
    this.prodItemList = await commonModel.getProdItemList()
    // 既に選択済みにチェックを行う
    const chartItems:iChartItems[] = JSON.parse(localStorage.getItem('chartItems') || '[]')
    for (let idx = 0; idx < chartItems.length; idx++) {
      this.selects.push(JSON.stringify(chartItems[idx]))
    }
    this.restrictSelectSensor()

    // 選択済みの過去の日付を読み込む
    this.currentPastDate = parseInt(localStorage.getItem('pastDate') || '0')

    // 現在のアカウントで取得できるBOX一覧を取得する
    const profile:iProfile = await commonModel.getMyProfile();
    // 管理者の場合
    if (profile.admin_flg) {
      this.groupMembers = await commonModel.getGroupMembres();
    } else {
      this.groupMembers = [profile]
    }

    this.currentUserId = this.groupMembers[0].user_id
    // ローカルストレージにユーザ情報が存在する場合はそのユーザを使用する
    const localUserId:string = localStorage.getItem('currentUserId') || ''
    if (localUserId && this.groupMembers.some(li => li.user_id == localUserId)) {
      this.currentUserId = localUserId
    }
    localStorage.setItem('currentUserId', this.currentUserId)

    await this.loadUserId()
  }

  private async changePastDate (): Promise<void> {
    localStorage.setItem('pastDate', String(this.currentPastDate))
    localStorage.setItem('chartType', 'detail')
    this.$emit('reChart')
  }

  private async loadUserId (): Promise<void> {
    this.battPercentList = []
    this.latestList = []
    this.boxes = await commonModel.getBoxes(this.currentUserId)

    let promiseLatest = []
    let promiseBattPercent = []
    for (let bIdx = 0; bIdx < this.boxes.length; bIdx++) {
      const box:iBoxes = this.boxes[bIdx] || {id:0}

      // バッテリー取得のAPIアクセス
      if (!(box.id == 0)) {
        promiseBattPercent.push(commonModel.getBattPercent(box.id))
      }

      // 最新値取得のAPIアクセス
      if (box.box_type && box.box_type.data_list) {
        const list:iBoxDataList[] = box.box_type.data_list
        for (let idx = 0; idx < list.length; idx++) {
          promiseLatest.push(commonModel.getLatest(
            box.id,
            list[idx].key || ''
          ))

          // もし体感温度が存在すれば、体感ストレスの取得も追加する
          if (list[idx].key == 'cor_temp') {
            promiseLatest.push(commonModel.getLatest(box.id, 'cor_thi'))
          }
        }
      }
    }

    this.battPercentList = await Promise.all(promiseBattPercent)
    this.latestList = await Promise.all(promiseLatest)
  }

  private async changeUserId (): Promise<void> {
    await this.loadUserId()
    localStorage.setItem('currentUserId', this.currentUserId)
    localStorage.setItem('chartType', 'detail')
    this.$emit('reChart')
  }

  private clickSensorTitle (json: string, type: string): void {
    // 2要素以上＆選択されていない要素の場合は、追加できない
    if (this.selectedTypes.length >= 2 && !this.selectedTypes.some(value => value == type)) {
      return
    }
    // クリックされたのが既に存在する要素か調べる
    const idx:number = this.selects.findIndex(value => value == json)
    if (idx == -1) {
      this.selects.push(json)
    } else {
      this.selects.splice(idx, 1)
    }
    this.selectSensor()
  }

  private clickProdTitle (): void {
    // 生産量のボタンが押されたときに表示が月表示(30日)になってない場合は、切り替える
    if (!(this.currentPeriod == 30)) {
      this.currentPeriod = 30
    }
  }


  private judgeSelectClass (json: string, type: string): string {
    if (this.selectedTypes.length >= 2 && !this.selectedTypes.some(value => value == type)) {
      return ''
    }
    if (this.selects.some(value => value == json)) {
      return 'select-sensor-selecting'
    }
    return 'select-sensor-selectable'
  }

  private createPastDateList (): {name:string,value:number}[] {
    let res = [{name: 'OFF', value: 0}]
    for (let idx = 1; idx <= 1000; idx++) {
      res.push({
        name: String(idx) + '日前',
        value: idx
      })
    }
    return res
  }
}
