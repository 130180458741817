/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'サービスワーカーがアプリをキャッシュから提供しています。\n' +
        '詳細については、 https://goo.gl/AFskqB をご覧ください。'
      )
    },
    registered() {
      console.log('サービスワーカーが登録されました。')
    },
    cached() {
      console.log('コンテンツはオフラインで使用するためにキャッシュされています。')
    },
    updatefound() {
      console.log('新しいコンテンツがダウンロードされています。')
    },
    updated() {
      console.log('新しいコンテンツが利用可能です。 リフレッシュしてください。')
    },
    offline() {
      console.log('インターネットに接続されていません。 アプリはオフラインモードで実行されます。')
    },
    error(error) {
      console.error('サービスワーカーの登録中にエラーが発生しました。:', error)
    }
  })
}
