import axios from 'axios';
import dayjs from 'dayjs'
import {
    iSetting,
    iProfile,
    iBoxes,
    iLatest,
    iBattPercent,
    iEnvironment,
    iThiPercent,
    iProduction,
    iAlertsSettings,
    iAlertsRecipients,
    iAlertsHistories,
    iAlertsHistoriesCounts,
    iBoxDataList,
    iDataList
} from '@/@types/common';

let setting: iSetting = {
    version: '3.83',
    apiUrl: 'https://api.farmlog.jp',
    // apiUrl: 'http://157.7.140.153:9004',
}

let myProfile: iProfile = {user_id: ''}
const userList: iProfile[] = []
let groupMembers: iProfile[] = []
let userBoxesList: {
    userId: string,
    boxes: iBoxes[],
}[] = []

const boxList: {
    boxId: number,
    boxInfo: iBoxes,
}[] = []

export default {

    getSetting(): iSetting {
        return setting
    },

    putSetting(newSet: iSetting): void {
        setting = newSet;
    },

    removeUserBoxesList(): void {
        userBoxesList = []
    },

    sortDirection(dir:number, speed:number): string {
        // 風速が0の場合は風向を返さない
        if (speed == 0) {
            return '無風'
        }

        // 入ってきた値が359以上の場合は、0度～359度に収まるようにする
        while (dir >= 360) {
            dir = dir - 360
        }

        // それぞれの方角で、8方位に振り分ける
        let label = ''
        if (22.5 < dir && dir <= 67.5) {
            label = '北東'
        } else if (67.5 < dir && dir <= 112.5) {
            label = '東'
        } else if (112.5 < dir && dir <= 157.5) {
            label = '南東'
        } else if (157.5 < dir && dir <= 202.5) {
            label = '南'
        } else if (202.5 < dir && dir <= 247.5) {
            label = '南西'
        } else if (247.5 < dir && dir <= 292.5) {
            label = '西'
        } else if (292.5 < dir && dir <= 337.5) {
            label = '北西'
        } else {
            label = '北'
        }
        return label
    },

    async getMyProfile(): Promise<iProfile> {
        if (myProfile.user_id == '') {
            await axios.get(setting.apiUrl + '/users/me').then(function (response) {
                myProfile = response.data
            })
        }
        return myProfile
    },

    async getProdItemList(): Promise<iBoxDataList[]> {
        return [
            { name: '搾乳頭数', unit: '頭', key: 'head', type: 'head'},
            { name: '生乳生産量', unit: 'kg', key: 'milk', type: 'milk'},
            { name: '体細胞', unit: '千', key: 'cell', type: 'cell'},
            { name: '尿素窒素', unit: 'mg/dL', key: 'mun', type: 'mun'},
            { name: '脂肪', unit: '％', key: 'fat', type: 'fat'},
        ]
    },

    async putMyProfile(newProfile: iProfile): Promise<iProfile> {
        await axios.put(setting.apiUrl + '/users/me', newProfile).then(function (response) {
            myProfile = response.data
        })
        return myProfile
    },

    async getUsersFromUserid(userId: string): Promise<iProfile> {
        let list: iProfile = userList.find(li => li.user_id == userId) || {user_id: ''}
        if (list.user_id == '') {
            await axios.get(setting.apiUrl + '/users/' + userId).then(function (response) {
                list = response.data
                userList.push(list)
            })
        }
        return list;
    },

    async getGroupMembres(): Promise<iProfile[]> {
        if (groupMembers.length == 0) {
            await axios.get(setting.apiUrl + '/users/group_members').then(function (response) {
                groupMembers = response.data
            })
        }
        return groupMembers;
    },

    async getBoxes(userId:string): Promise<iBoxes[]> {
        let boxes: iBoxes[] = []
        const list: {
            userId: string,
            boxes: iBoxes[],
        } = userBoxesList.find(li => li.userId == userId) || {userId: '', boxes: []}

        if (list.userId == '') {
            await axios.get(setting.apiUrl + '/users/' + userId + '/boxes').then(function (response) {
                boxes = response.data
                userBoxesList.push({
                    userId: userId,
                    boxes: boxes,
                })
            })
        } else {
            boxes = list.boxes
        }
        return boxes;
    },

    async getBoxInfo(boxId: number): Promise<iBoxes> {
        let box:{
            boxId: number,
            boxInfo: iBoxes,
        } = boxList.find(li => li.boxId == boxId) || {boxId: 0, boxInfo: {id: 0}}

        if (box.boxId == 0) {
            await axios.get(setting.apiUrl + '/boxes/' + String(boxId)).then(function (response) {
                box = {
                    boxId: boxId,
                    boxInfo: response.data,
                }
                boxList.push(box)
            })
        }
        return box.boxInfo;
    },

    async getLatest(boxId: number, dataTypeKey: string): Promise<iLatest> {
        let latest:iLatest = {box_id:0}
        const params = {'params': {'data_type_key': dataTypeKey}}

        await axios.get(setting.apiUrl + '/boxes/' + boxId + '/environment/latest', params).then(function (response) {
            latest = response.data
        })

        // keyが風速センサの場合は、風向を取得しに行く処理を入れる
        if (dataTypeKey == 'ws303_speed') {
            const dir:iLatest = await this.getLatest(boxId, 'ws303_dir')
            latest['label'] = this.sortDirection(dir['value'] || 0, latest['value'] || 0)
        }

        // keyが体感ストレスの場合は、ラベルを追加する
        if (dataTypeKey == 'cor_thi') {
            const value = latest['value'] || 0
            if (value >= 79) {
                latest['label'] = '危険'
            } else if (value >= 72) {
                latest['label'] = '注意'
            } else {
                latest['label'] = '快適'
            }
        }

        return latest;
    },

    async getBattPercent(boxId: number): Promise<iBattPercent> {
        let res = {box_id: 0}
        await axios.get(setting.apiUrl + '/boxes/' + boxId + '/battery/percent').then(function (response) {
            res = response.data
        })
        return res;
    },

    async getThiPercent(boxId: number, startDate: string, endDate: string, type: string): Promise<iThiPercent> {
        const localStorageKey: string = 'apiData-getThiPercent-' + String(boxId) + '-' + startDate + '-' + endDate + '-' + type
        const localData = localStorage.getItem(localStorageKey)
        if (localData) {
            return JSON.parse(localData)
        }

        let res = {box_id: 0}
        const params = {
            'params': {
                'start_date': startDate,
                'end_date': endDate,
                'type': type,
            }
        }
        await axios.get(setting.apiUrl + '/boxes/' + boxId + '/thi/percent', params).then(function (response) {
            res = response.data
        })

        if (dayjs(startDate).diff(dayjs()) < 0 && dayjs(endDate).diff(dayjs()) < 0) {
            localStorage.setItem(localStorageKey, JSON.stringify(res))
        }
        return res;
    },

    async getEnvironment(boxId: number, dataTypeKey: string, startTime: string, endTime: string, type: string): Promise<iEnvironment> {
        const localStorageKey: string = 'apiData-getEnvironment-' + String(boxId) + '-' + dataTypeKey + '-' + startTime + '-' + endTime + '-' + type
        const localData = localStorage.getItem(localStorageKey)
        if (localData) {
            return JSON.parse(localData)
        }

        let res:iEnvironment = {box_id: 0}
        const params = {'params': {
            'data_type_key': dataTypeKey,
            'start_time': startTime,
            'end_time': endTime
        }}
        await axios.get(setting.apiUrl + '/boxes/' + boxId + '/environment/' + type, params).then(function (response) {
            res = response.data
        })

        // keyが風速センサの場合は、風向を取得しに行く処理を入れる
        // TODO: type==dailyの対応が出来ていない
        if (dataTypeKey == 'ws303_speed') {
            const dir: iEnvironment = await this.getEnvironment(boxId, 'ws303_dir', startTime, endTime, type)
            if (res.data_list && dir.data_list) {
                for (let idx = 0; idx < res.data_list.length; idx++) {
                    const data:iDataList = res.data_list[idx] || {}
                    if (data.datetime) {
                        const findData:iDataList = dir.data_list.find(li => li.datetime == data.datetime) || {}
                        data['dirData'] = findData
                    }
                    if (data.date) {
                        const findData: iDataList = dir.data_list.find(li => li.date == data.date) || {}
                        data['dirData'] = findData
                    }
                }
            }
        }

        if (dayjs(startTime).diff(dayjs()) < 0 && dayjs(endTime).diff(dayjs()) < 0) {
            localStorage.setItem(localStorageKey, JSON.stringify(res))
        }
        return res
    },

    async getProduction(userId: string, dataTypeKey: string, startDate: string, endDate: string, type: string): Promise<iProduction> {
        let res = {user_id: '0'}
        const params = {
            'params': {
                'data_type_key': dataTypeKey,
                'start_date': startDate,
                'end_date': endDate
            }
        }
        await axios.get(setting.apiUrl + '/users/' + userId + '/production/' + type, params).then(function (response) {
            res = response.data
        })
        return res
    },

    async putProduction(userId: string, value: number|undefined, date: string, key: string, type: string): Promise<void> {
        const params = {'value': value, 'date': date, 'data_type_key': key}
        await axios.put(setting.apiUrl + '/users/' + userId + '/production/' + type, params)
    },

    async postProductionCsv(userId: string, data: FormData): Promise<void> {
        await axios.post(setting.apiUrl + '/users/' + userId + '/production/upload', data)
    },

    async getAlertsSettings(boxId: number): Promise<iAlertsSettings[]> {
        let settings: iAlertsSettings[] = []
        const params = { 'params': { 'box_id': String(boxId) } }
        await axios.get(setting.apiUrl + '/alerts/settings', params).then(function (response) {
            settings = response.data
        })
        return settings;
    },

    async postAlertsSettings(boxId: number, dataTypeKey: string, thresholdValue: number, position: boolean): Promise<void> {
        const params = {
            'box_id': boxId,
            'data_type_key': dataTypeKey,
            'threshold_value': thresholdValue,
            'position': position,
        }
        await axios.post(setting.apiUrl + '/alerts/settings', params)
    },

    async putAlertsSettings(settingId: number, thresholdValue: number): Promise<void> {
        const params = {'threshold_value': thresholdValue}
        await axios.put(setting.apiUrl + '/alerts/settings/' + String(settingId), params)
    },

    async deleteAlertsSettings(settingId: number): Promise<void> {
        await axios.delete(setting.apiUrl + '/alerts/settings/' + String(settingId))
    },

    async getAlertsRecipients(boxId: number): Promise<iAlertsRecipients[]> {
        let recipients: iAlertsRecipients[] = []
        const params = { 'params': { 'box_id': String(boxId) } }
        await axios.get(setting.apiUrl + '/alerts/recipients', params).then(function (response) {
            recipients = response.data
        })
        return recipients;
    },

    async postAlertsRecipients(boxId: number, email: string, settingList: number[]): Promise<void> {
        const params = {
            'box_id': boxId,
            'email': email,
            'associated_setting_list': settingList,
        }
        await axios.post(setting.apiUrl + '/alerts/recipients', params)
    },

    async putAlertsRecipients(recipientId: number, email: string, settingList: number[]): Promise<void> {
        const params = {
            'email': email,
            'associated_setting_list': settingList,
        }
        await axios.put(setting.apiUrl + '/alerts/recipients/' + String(recipientId), params)
    },

    async deleteAlertsRecipients(recipientId: number): Promise<void> {
        await axios.delete(setting.apiUrl + '/alerts/recipients/' + String(recipientId))
    },

    async putBoxName(boxid: number, name: string): Promise<void> {
        await axios.put(setting.apiUrl + '/boxes/' + String(boxid), { name: name })
    },

    async postChangePassword(oldPass: string, newPass: string): Promise<void> {
        await axios.post(setting.apiUrl + '/change', {old_password: oldPass, new_password: newPass})
    },

    async getAlertsHistories(limit: number, userId: string, startDate = '', endDate = ''): Promise<iAlertsHistories[]> {
        let alerts: iAlertsHistories[] = []
        const params: {[key: string]: string;} = {
            'limit': String(limit),
            'user_id': userId,
        }
        if (startDate) {params['start_date'] = startDate}
        if (endDate) {params['end_date'] = endDate}
        await axios.get(setting.apiUrl + '/alerts/histories', {'params': params}).then(function (response) {
            alerts = response.data
        })
        return alerts;
    },

    async getAlertsHistoriesCounts(userId: string, startDate: string, endDate:string): Promise<iAlertsHistoriesCounts[]> {
        let alerts: iAlertsHistoriesCounts[] = []
        const params = {'params': {
            'start_date': startDate,
            'end_date': endDate,
            'user_id': userId,
        }}
        await axios.get(setting.apiUrl + '/alerts/histories/counts', params).then(function (response) {
            alerts = response.data
        })
        return alerts;
    },

    async getEnvDownload(boxId: number, startDate: string, endDate: string, dataFormat: string): Promise<string> {
        let dataText = ''
        const params = {'params': {
            'start_date': startDate,
            'end_date': endDate,
            'data_format': dataFormat,
        }}
        await axios.get(setting.apiUrl + '/boxes/' + boxId + '/environment/download', params).then(function (response) {
            dataText = response.data
        })
        return dataText
    },

    async getProdDownload(userId: string, startDate: string, endDate: string): Promise<string> {
        let dataText = ''
        const params = {
            'params': {
                'start_date': startDate,
                'end_date': endDate,
            }
        }
        await axios.get(setting.apiUrl + '/users/' + userId + '/production/download', params).then(function (response) {
            dataText = response.data
        })
        return dataText
    },

};

